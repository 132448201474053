import "./index.css"

import isEmpty from "lodash/isEmpty"
import React, { useState } from "react"
import { SwitchTransition } from "react-transition-group"

import { ErrorMessage } from "../components/rsvp/form/error"
import { Loader } from "../components/rsvp/form/loader"
import { Menu } from "../components/menu"
import { minFetch } from "../components/rsvp/form/min-fetch"
import { RegistryBackground } from "../components/registry/background"
import { useError } from "../hooks/use-error"
import { useInitialLoad } from "../hooks/use-initial-load"

import { FormContainer } from "../components/transition-form/form-container"

import SEO from "../components/seo"

const Sign = ({ goToNextStep }) => {
  const initialLoad = useInitialLoad()

  const [error, setError] = useError()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [message, setMessage] = useState("")
  const [name, setName] = useState("")

  const submit = async () => {
    setError()
    setIsSubmitting(true)

    await minFetch(
      "/.netlify/functions/sign-guest-book",
      {
        body: JSON.stringify({
          message,
          name,
        }),
        method: "POST",
      },
      () => {
        setError()
        goToNextStep()
      },
      (payload) => {
        setError(payload)
        setIsSubmitting(false)
      },
    )
  }

  return (
    <div>
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <div
          className="text"
          style={{ marginBottom: "32px", maxWidth: "500px", textAlign: "start" }}>
          Hello,<br/><br/>
          We just wanted you to know, big or small, you have impacted our lives in so many beautiful ways.<br/><br/>
          We want to thank you for that, and It would mean so much to have you sign our guest book.<br/><br/>
          We look forward to hearing from you.
        </div>
      </div>
      <div
        className="animate-opacity pink-form"
        style={{ opacity: initialLoad ? 1 : 0 }}
      >
        <div
          className="animate-opacity"
          style={{
            display: "flex",
            flexDirection: "column",
            marginBottom: "24px",
          }}
        >
          <div className="subheader text" style={{ marginBottom: "8px", textAlign: "start" }}>Who is this message
            from?
          </div>
          <input
            disabled={isSubmitting}
            id="name"
            name="name"
            onChange={(e) => setName(e.target.value)}
            value={name}
          />
        </div>
        <div
          className="animate-opacity"
          style={{
            display: "flex",
            flexDirection: "column",
            marginBottom: "16px",
          }}
        >
          <div className="subheader text" style={{ marginBottom: "8px", textAlign: "start" }}>What would you like
            to say?
          </div>
          <textarea
            disabled={isSubmitting}
            id="message"
            name="message"
            onChange={(e) => setMessage(e.target.value)}
            value={message}
          />
        </div>
        {isSubmitting && (
          <>
            <div style={{ marginTop: "2px" }}/>
            <Loader/>
          </>
        )}
        {!isSubmitting && !error && <div style={{ height: "12px" }}/>}
        <ErrorMessage error={error}/>
        <button
          disabled={
            isSubmitting ||
            isEmpty(name) ||
            isEmpty(message)
          }
          onClick={submit}
          style={
            isSubmitting ||
            isEmpty(name) ||
            isEmpty(message) ? {
              color: "#ffffff91",
              backgroundColor: "#8e7272e3",
              cursor: "not-allowed",
            } : {}
          }
          type="submit"
        >
          Submit
        </button>
      </div>
    </div>
  )
}

const ThankYou = ({ goToNextStep }) => {
  const initialLoad = useInitialLoad()

  return (
    <div className="pink-form-container" style={{ marginTop: "64px" }}>
      <div
        className="animate-opacity pink-form"
        style={{ opacity: initialLoad ? 1 : 0 }}
      >
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <div className="text" style={{ fontSize: "24px", marginBottom: "8px", maxWidth: "500px", textAlign: "center" }}>
            Thank you, your message has been received.<br/>
          </div>
        </div>
        <button
          onClick={goToNextStep}
          style={{ alignSelf: "center", width: "175px" }}
        >
          Send Another
        </button>
      </div>
    </div>
  )
}

const steps = [
  Sign,
  ThankYou,
];

const Page = (props) => {
  const { location } = props

  const initialLoad = useInitialLoad()

  const [step, setStep] = useState(0);

  const goToNextStep = () => {
    setStep((step + 1) % 2);
  };

  const Step = steps[step];

  return (
    <>
      <div className="container overlay"/>
      <div className="animate-blur container" style={{ filter: "blur(0px)" }}>
        <RegistryBackground
          className="animate-opacity full-page-background"
          style={{ opacity: initialLoad ? 1 : 0 }}
        />
      </div>
      <Menu location={location}/>
      <SwitchTransition mode="out-in">
        <FormContainer
          key={step}
          timeout={500}
          mountOnEnter
          unmountOnExit
        >
          <Step goToNextStep={goToNextStep}/>
        </FormContainer>
      </SwitchTransition>
    </>
  )
}

const IndexPage = (props) => (
  <>
    <SEO title="Gallery"/>
    <Page location={props.location}/>
  </>
)

export default IndexPage
