export const minFetch = async (
  url,
  options,
  onSuccess,
  onError,
  minTime = 1000
) => {
  const start = new Date()

  const response = await fetch(url, options)

  let payload;

  try {
    payload = await response.json()
  } catch {
    payload = await response.text();
  }

  const elapsed = new Date() - start

  if (elapsed <= minTime) {
    await new Promise(resolve => setTimeout(resolve, minTime - elapsed))
  }

  if (response.status < 300) {
    onSuccess(payload)
  } else {
    onError(payload)
  }
};
