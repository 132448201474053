import {Transition} from "react-transition-group";

import React from "react";

import "./index.css";

export const FormContainer = (props) => {
  const {children, ...rest} = props;

  return (
    <Transition appear {...rest}>
      {state => (
        <div
          className="pink-form-container"
          style={{
            display: state === "exited" ? "none" : "block",
            opacity: state === "entered" ? 1 : 0,
            transition: "0.5s",
          }}
        >
          {React.cloneElement(
            children,
            {transitionedIn: state === "entered"})
          }
        </div>
      )}
    </Transition>
  )
};
