import isNil from "lodash/isNil";
import React from "react";

export const ErrorMessage = (props) => {
  const {error} = props;

  if (isNil(error)) {
    return null;
  }

  return (
    <div className="text" style={{marginTop: "8px", textAlign: "start"}}>
      {error}
    </div>
  );
};
