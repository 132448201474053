import get from "lodash/get";
import {useCallback, useState} from "react";

const getError = (error) => {
  if (get(error, "error")) {
    return error.error;
  }

  return error;
};

export const useError = () => {
  const [value, setState] = useState();

  const setError = useCallback((error) => {
    setState(getError(error));
  }, [setState]);

  return [value, setError];
};
